<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-12 14:57:31
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-03-28 21:39:19
 * @Description  : 綁定信用卡
-->
<template>
    <div class="container">
        <div class="title"><span>編輯信用卡</span></div>
        <div class="bg" :style="{ minHeight: boxheight }">
            <form :style="{ minHeight: boxheight }">
                <!-- <span id="cardtype"></span> -->
                <div class="card-number-group">
                    <label for="card-number" class="control-label">卡號</label>
                    <div class="form-control card-number"></div>
                </div>
                <div class="expiration-date-group">
                    <label for="expiration-date" class="control-label"
                        >卡片到期日</label
                    >
                    <div
                        class="form-control expiration-date"
                        id="tappay-expiration-date"
                    ></div>
                </div>
                <div class="ccv-group">
                    <label for="ccv" class="control-label">卡片後三碼</label>
                    <div class="form-control ccv"></div>
                </div>

                <button type="submit" class="sms-way">綁定</button>
                <pre class="jumbotron text-left" id="curl"></pre>
            </form>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";
import $ from "jquery";
import { Toast } from "vant";

export default {
    name: "",
    data() {
        return {};
    },
    methods: {
        initTappayConfig() {
            window.TPDirect.card.setup({
                fields: {
                    number: {
                        element: ".form-control.card-number",
                        placeholder: "**** **** **** ****",
                    },
                    expirationDate: {
                        element: document.getElementById(
                            "tappay-expiration-date"
                        ),
                        placeholder: "MM / YY",
                    },
                    ccv: {
                        element: $(".form-control.ccv")[0],
                        placeholder: "後三碼",
                    },
                },
                styles: {
                    input: {
                        color: "gray",
                    },
                    "input.ccv": {
                        // 'font-size': '16px'
                    },
                    ":focus": {
                        color: "black",
                    },
                    ".valid": {
                        color: "green",
                    },
                    ".invalid": {
                        color: "red",
                    },
                    "@media screen and (max-width: 400px)": {
                        input: {
                            color: "orange",
                        },
                    },
                },
                // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
                isMaskCreditCardNumber: true,
                maskCreditCardNumberRange: {
                    beginIndex: 6,
                    endIndex: 11,
                },
            });

            // listen for TapPay Field
            window.TPDirect.card.onUpdate(function (update) {
                /* Disable / enable submit button depend on update.canGetPrime  */
                /* ============================================================ */

                // update.canGetPrime === true
                //     --> you can call TPDirect.card.getPrime()
                // const submitButton = document.querySelector('button[type="submit"]')
                if (update.canGetPrime) {
                    // submitButton.removeAttribute('disabled')
                    $('button[type="submit"]').removeAttr("disabled");
                } else {
                    // submitButton.setAttribute('disabled', true)
                    $('button[type="submit"]').attr("disabled", true);
                }

                /* Change card type display when card type change */
                /* ============================================== */

                // cardTypes = ['visa', 'mastercard', ...]
                var newType =
                    update.cardType === "unknown" ? "" : update.cardType;
                $("#cardtype").text(newType);

                /* Change form-group style when tappay field status change */
                /* ======================================================= */

                // number 欄位是錯誤的
                if (update.status.number === 2) {
                    setNumberFormGroupToError(".card-number-group");
                } else if (update.status.number === 0) {
                    setNumberFormGroupToSuccess(".card-number-group");
                } else {
                    setNumberFormGroupToNormal(".card-number-group");
                }

                if (update.status.expiry === 2) {
                    setNumberFormGroupToError(".expiration-date-group");
                } else if (update.status.expiry === 0) {
                    setNumberFormGroupToSuccess(".expiration-date-group");
                } else {
                    setNumberFormGroupToNormal(".expiration-date-group");
                }

                if (update.status.ccv === 2) {
                    setNumberFormGroupToError(".ccv-group");
                } else if (update.status.ccv === 0) {
                    setNumberFormGroupToSuccess(".ccv-group");
                } else {
                    setNumberFormGroupToNormal(".ccv-group");
                }
            });

            $("form").on("submit", function (event) {
                event.preventDefault();

                // fix keyboard issue in iOS device
                forceBlurIos();

                const tappayStatus =
                    window.TPDirect.card.getTappayFieldsStatus();
                console.log("tappayStatus: ", tappayStatus);

                // Check TPDirect.card.getTappayFieldsStatus().canGetPrime before TPDirect.card.getPrime
                if (tappayStatus.canGetPrime === false) {
                    alert("can not get prime");
                    return;
                }

                // Get prime
                window.TPDirect.card.getPrime(function (result) {
                    console.log("result: ", result);
                    if (result.status !== 0) {
                        alert("get prime error " + result.msg);
                        return;
                    }
                    bindCard(result.card.prime);
                    // alert("get prime 成功，prime: " + result.card.prime);
                    //     var command = `
                    // Use following command to send to server \n\n
                    // curl -X POST https://sandbox.tappaysdk.com/tpc/payment/pay-by-prime \\
                    // -H 'content-type: application/json' \\
                    // -H 'x-api-key: partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM' \\
                    // -d '{
                    //     "partner_key": "partner_6ID1DoDlaPrfHw6HBZsULfTYtDmWs0q0ZZGKMBpp4YICWBxgK97eK3RM",
                    //     "prime": "${result.card.prime}",
                    //     "amount": "1",
                    //     "merchant_id": "GlobalTesting_CTBC",
                    //     "details": "Some item",
                    //     "cardholder": {
                    //         "phone_number": "+886923456789",
                    //         "name": "王小明",
                    //         "email": "LittleMing@Wang.com",
                    //         "zip_code": "100",
                    //         "address": "台北市天龍區芝麻街1號1樓",
                    //         "national_id": "A123456789"
                    //     }
                    // }'`.replace(/ /g, "");
                    // .replace(/\\/g, '')
                    // console.log("command: ", command);

                    // document.querySelector("#curl").innerHTML = command;
                });
            });

            function setNumberFormGroupToError(selector) {
                $(selector).addClass("has-error");
                $(selector).removeClass("has-success");
            }

            function setNumberFormGroupToSuccess(selector) {
                $(selector).removeClass("has-error");
                $(selector).addClass("has-success");
            }

            function setNumberFormGroupToNormal(selector) {
                $(selector).removeClass("has-error");
                $(selector).removeClass("has-success");
            }

            function forceBlurIos() {
                if (!isIos()) {
                    return;
                }
                var input = document.createElement("input");
                input.setAttribute("type", "text");
                // Insert to active element to ensure scroll lands somewhere relevant
                document.activeElement.prepend(input);
                input.focus();
                input.parentNode.removeChild(input);
            }

            function isIos() {
                return (
                    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                    !window.MSStream
                );
            }
            let that = this;
            async function bindCard(prime) {
                try {
                    const res = await that.$axios.post(
                        "tapPay/saveCreditCard",
                        {
                            prime: prime,
                            userId: that.userId,
                        }
                    );
                    console.log("res: ", res);
                    if (res.data.success) {
                        Toast("綁定成功");
                        that.$router.push("/map");
                    }
                } catch (error) {
                    handleErr({ ...error, app: that.$parent });
                }
            }
        },
        // async bindCard(prime) {
        //     try {
        //         const res = await this.$axios.post("tapPay/saveCreditCard", {
        //             prime: prime,
        //             userId: this.userId,
        //         });
        //         console.log("res: ", res);
        //         if (res.data.success) {
        //             Toast("綁定成功");
        //             this.$router.push("/map");
        //         }
        //     } catch (error) {
        //         handleErr({ ...error, app: this.$parent });
        //     }
        // },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                console.log("res: ", res);
                if (res.data.success) {
                    this.userId = res.data.result.id;
                    console.log("this.userId: ", this.userId);
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        window.TPDirect.setupSDK(
            "14250",
            "app_DNHao9t3KedE85B5UA4JkXJueWeMCeehDrEPTIJIStvCF0MVaatIutqXpn0N",
            "production"
        );
        this.token = window.$cookies.get("token");
        if (this.token) {
            this.$axios.defaults.headers.common["token"] = this.token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        // this.userId = JSON.parse(sessionStorage.getItem("userInfo")).userId;
        this.getUserInfo();
        this.initTappayConfig();
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #f8f8f8;
    text-align: center;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    p {
        overflow: hidden;
    }
    .card-number-group {
        text-align: left;
    }
    .expiration-date-group {
        text-align: left;
    }
    .ccv-group {
        text-align: left;
    }
}
.tpfield {
    height: 40px;
    width: 300px;
    border: 1px solid gray;
    margin: 5px 0;
    padding: 5px;
}
.login-way {
    .sms-way {
        background: #2c394e;
        border-radius: 25px;
        height: 50px;
        color: #ffffff;
        width: 100px;
        line-height: 50px;
        margin: 20px;
        text-align: center;
    }
}
.title {
    background: #fee26c;
    text-align: center;
    padding: 10px;
}

body {
    margin: 20px 0;
}
.jumbotron {
    text-align: center;
}
.text-left {
    text-align: left;
}
.container {
    max-width: 750px;
}
form {
    padding: 40px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.tappay-field-focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
}
.has-error .tappay-field-focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.has-success .tappay-field-focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}
.sms-way {
    background: #2c394e;
    border-radius: 25px;
    height: 50px;
    color: #ffffff;
    line-height: 50px;
    margin-top: 20px;
    margin: 20px;
    width: 200px;
    text-align: center;
}
</style>
